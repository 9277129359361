/* entire document */
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

* {
  font-weight: 400 !important;
  /* color: #000000 !important; */
  font-family: 'Centra', sans-serif !important;
}

html {
  scroll-behavior: smooth !important;
  scroll-padding-top: 0px !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 450 !important;
}

p,
a,
li,
button,
ul {
  line-height: 1.5 !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

img {
  width: 100% !important;
  height: auto !important;
}

/* all sections */
.app-bar {
  background-color: transparent !important;
  padding: 40px 0 !important;
  box-shadow: none !important;
  transition: 0.6s ease-in-out !important;
}

.box {
  margin: -8px 0px 0px -8px !important;
  width: 100vw !important;
  /* height: calc(100vh + 80px) !important; */
  height: calc(100vh - 20px) !important;
  box-shadow: 0 3px 10px 2px rgb(0, 0, 0) !important;
}

.box-overflow {
  margin: 60px 0px 60px 0px !important;
  /* width: 100vw !important; */
  /* max-height: 100% !important; */
  overflow: scroll !important;
}

.box-color-1 {
  background-image: linear-gradient(to bottom, #fff, rgba(176, 176, 176, 0.5)) !important;
}

.box-color-2 {
  background-image: linear-gradient(to bottom, rgb(30, 30, 30), rgb(25, 25, 25)) !important;
  color: white !important;
}

.scrolled-app-bar {
  background-color: #fff !important;
  /* padding: 18px 0 !important; */
  box-shadow: none !important;
  transition: 0.6s ease-in-out !important;
  box-shadow: 0 6px 10px -3px rgb(0, 0, 0) !important;
}

.scroll-container {
  max-height: 60vh !important;
  overflow: scroll !important;
}

.scroll-container-contact {
  max-height: 90vh !important;
  overflow: scroll !important;
}

/* all elements */
.app-bar-link-text {
  margin: 10px !important;
  color: #000 !important;
}

.app-bar-drawer {
  width: 200% !important;
  text-align: center !important;
}

.app-bar-partition {
  margin: 11px 12px 0px 12px !important;
  color: rgba(38, 38, 38, 0.273) !important;
}

.axis-vertical {
  visibility: hidden !important;
}

.box-shadow-1 {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}

.card-text-box {
  position: absolute !important;
  text-align: center !important;
  max-width: 70%;
  top: 65% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  transition: 0.5s ease-in-out !important;
  opacity: 0 !important;
  width: 100% !important;
  background-color: transparent !important;
}

.card-img-box:hover .card-text-box {
  top: 50% !important;
  opacity: 1 !important;
}

.card-text {
  font-size: 16px;
  background-color: transparent !important;
}

.card-title {
  font-size: 24px !important;
  font-weight: 550 !important;
  background-color: transparent !important;
  margin: 10px auto !important;
}

.card-img-box {
  max-height: 260px !important;
  min-height: 260px !important;
  position: relative !important;
  border-radius: 6px !important;
  overflow: hidden !important;
}

.card-img-box::before {
  content: '' !important;
  background: linear-gradient(220.21deg, #edededbf, #ffffff) !important;
  opacity: 0.85 !important;
  position: absolute !important;
  width: 100% !important;
  height: 0 !important;
  transition: 0.4s ease-in-out !important;
}

.card-img-box:hover::before {
  height: 100% !important;
}

.card-img {
  max-height: 260px !important;
  min-height: 260px !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  overflow: hidden !important;
}

.carousel-status {
  color: white !important;
  margin: 16px 16px 0px 0px !important;
  padding: 6px 12px !important;
  font-size: 18px !important;
  background: linear-gradient(220.21deg, #313131bf, #858585) !important;
  border-radius: 6px !important;
}

.carousel-img {
  /* height: 60vh; */
  /* This ensures the image takes up 50% of the viewport height. You can adjust this percentage as per your design requirement */
  width: 100% !important;
  /* This ensures the image takes up 100% of the container width */
  object-fit: cover;
  overflow: hidden;
}

@media (max-width: 600px) {
  .carousel-img {
    max-height: 30vh;
    max-width: 100vw;
  }
}

.carousel-img img {
  object-fit: cover;
  object-position: center;
  width: 100% !important;
  height: 100%;
}

.contact-btn {
  color: rgb(255, 255, 255) !important;
  background-color: #121212 !important;
  height: 50px;
  max-width: 160px;
  font-size: 16px !important;
  margin: 12px auto !important;
}

.contact-btn:hover {
  color: rgb(0, 0, 0) !important;
  background-color: hsl(0, 0%, 71%) !important;
}

.contact-container {
  padding: 80px 40px !important;
  background-color: rgb(34, 34, 34) !important;
  color: white !important;
}

.control-prev {
  margin-left: 24px !important;
}

.control-arrow {
  background-color: black !important;
  width: 100px !important;
  z-index: 1 !important;
}

.copyright {
  text-align: right !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: rgb(242, 242, 242) !important;
}

.mission-overlay {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255), rgba(255, 255, 255, 0.816), rgba(255, 255, 255, 0.621), rgba(255, 255, 255, 0.112), rgba(255, 255, 255, 0.044)) !important;
}

.mission-video {
  position: 'absolute' !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100vh !important;
  object-fit: cover !important;
}

/* .mission-statement-container {
  height: 40vh !important;
  margin: 24vh auto !important;
  text-align: center !important;
  padding: 80px 120px !important;
  border-radius: 6px !important;
  width: 39% !important;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) !important;
}

.mission-statement {
  height: 40vh !important;
  font-size: 26px !important;
  font-weight: 400 !important;
  color: white !important;
  line-height: 1.9em !important;
  letter-spacing: 3px !important;
  text-transform: uppercase !important;
  text-align: justify-all !important;
} */

p.success {
  color: green !important;
}

p.danger {
  color: red !important;
}

.scrolled-logo {
  margin-top: 4px !important;
  max-width: 230px !important;
}

.showcase-title {
  padding: 8px 0px 8px 0px !important;
  border-radius: 6px 6px 0px 0px !important;
  background-color: rgb(226, 226, 226);
  text-align: center !important;
  margin: 26px auto 0px auto !important;
  width: 100% !important;
  font-weight: bold !important;
  font-size: 14px !important;
  /* color: #f2f2f2e9; */
}

.section-header {
  font-size: 40px !important;
  font-weight: 400 !important;
}

.section-text {
  font-size: 18px !important;
  margin-top: 30px !important;
  line-height: 2.8em !important;
}

.sign-img {
  margin-top: 30px !important;
  width: 50% !important;
}

.testimonial {
  text-align: left;
}

.text-white {
  color: white !important;
}

/* material ui overrides */
.MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #000 !important;
}

.MuiDrawer-paper {
  width: 35% !important;
}

.MuiListItemText-primary {
  text-align: center !important;
  margin: 10px 0px 10px 0px !important;
}

.MuiIconButton-root {
  color: #000 !important;
}

.MuiListItemText-root {
  color: #000 !important;
}

/* phone */
@media (max-width: 375px) {
  .logo {
    margin-top: 4px !important;
    max-width: 200px !important;
  }

  .mission-statement-container {
    margin: 22vh auto !important;
    text-align: center !important;
    padding: 30px 50px !important;
    border-radius: 6px !important;
    width: 40% !important;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) !important;
    max-height: 500px !important;
  }

  .mission-statement {
    font-size: 16px !important;
    font-weight: 100 !important;
    color: white !important;
    line-height: 2em !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
    text-align: justify-all !important;
  }
}

@media (min-width: 376px) and (max-width: 450px) {
  .logo {
    margin-top: 4px !important;
    max-width: 240px !important;
  }

  .mission-statement-container {
    margin: 20vh auto !important;
    text-align: center !important;
    padding: 30px 50px !important;
    border-radius: 6px !important;
    width: 40% !important;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) !important;
    overflow: scroll !important;
  }

  .mission-statement {
    font-size: 18px !important;
    font-weight: 100 !important;
    color: white !important;
    line-height: 2em !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
    text-align: justify-all !important;
  }
}

@media (min-width: 450px) and (max-width: 767px) {
  .logo {
    margin-top: 4px !important;
    max-width: 240px !important;
  }

  .mission-statement-container {
    margin: 28vh auto !important;
    text-align: center !important;
    padding: 30px 50px !important;
    border-radius: 6px !important;
    width: 40% !important;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) !important;
    overflow: scroll !important;
  }

  .mission-statement {
    font-size: 18px !important;
    font-weight: 100 !important;
    color: white !important;
    line-height: 2em !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
    text-align: justify-all !important;
  }
}

/* tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .logo {
    margin-top: 4px !important;
    max-width: 280px !important;
  }

  .mission-statement-container {
    margin: 26vh auto !important;
    text-align: center !important;
    padding: 60px 60px !important;
    border-radius: 6px !important;
    width: 40% !important;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) !important;
  }

  .mission-statement {
    font-size: 22px !important;
    color: white !important;
    font-weight: 200 !important;
    line-height: 2em !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
    text-align: justify-all !important;
  }
}

/* desktop */
@media (min-width: 1023px) and (max-width: 1200px) {
  .logo {
    margin-top: 4px !important;
    max-width: 280px !important;
  }

  .mission-statement-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24vh auto !important;
    text-align: center !important;
    padding: 80px 120px !important;
    border-radius: 6px !important;
    width: 40% !important;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) !important;
  }

  .mission-statement {
    font-size: 23px !important;
    font-weight: 400 !important;
    color: white !important;
    line-height: 1.9em !important;
    letter-spacing: 3px !important;
    text-transform: uppercase !important;
    text-align: justify-all !important;
  }

}

/* desktop */
@media (min-width: 1201px) {
  .logo {
    margin-top: 4px !important;
    max-width: 280px !important;
  }

  .mission-statement-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32vh auto !important;
    text-align: center !important;
    padding: 80px 120px !important;
    border-radius: 6px !important;
    width: 30% !important;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) !important;
  }

  .mission-statement {
    font-size: 23px !important;
    font-weight: 400 !important;
    color: white !important;
    line-height: 1.9em !important;
    letter-spacing: 3px !important;
    text-transform: uppercase !important;
    text-align: justify-all !important;
  }

}